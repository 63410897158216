import React from "react";

interface FormHandlers<T> {
  formData: T;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFormData: React.Dispatch<React.SetStateAction<T>>;
}

export const useApplicationForm = <T extends Record<string, any>>(
  initialValues: T
): FormHandlers<T> => {
  const [formData, setFormData] = React.useState<T>(initialValues);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  return { formData, handleChange, setFormData };
};
