import React from "react";
import ApplicationSuccess from "../ApplicationSuccess";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../../components/NotFound/NotFound";
import { ApplicationForm } from "./components/ApplicationForm";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFetchOpening } from "../../api/hooks/openings/useFetchOpeningById";
import { useTelegram } from "../../utils/Telegram.Provider";
import { useGetProfile } from "../../api/hooks/profiles/useGetProfile";

const useStyles = makeStyles({
  baseContainer: {
    paddingTop: "16px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
    width: "100%",
  },
});

const ApplyJobPage = () => {
  const { openingId } = useParams<{ openingId: string }>();
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const classes = useStyles();

  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate(`/openings/${openingId}`);
  }, [navigate, openingId]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const { data: opening, isLoading: isOpeningLoading } =
    useFetchOpening(openingId);
  const { data, isLoading: isProfileLoading } = useGetProfile(
    webApp?.initDataUnsafe?.user?.id!
  );

  React.useEffect(() => {
    if (opening && webApp && opening.applicationLink) {
      webApp.openLink(opening.applicationLink);
      navigate("/");
    }
  }, [opening, webApp, navigate]);

  const isLoading = isOpeningLoading || isProfileLoading;

  if (isLoading)
    return (
      <Box className={classes.baseContainer}>
        <LoadingIndicator />
      </Box>
    );
  if (!opening)
    return (
      <Box className={classes.baseContainer}>
        <NotFound />
      </Box>
    );
  if (isSuccess)
    return (
      <Box className={classes.baseContainer}>
        <ApplicationSuccess />
      </Box>
    );

  return (
    <Box className={classes.baseContainer}>
      <ApplicationForm
        jobOpening={opening}
        profile={data?.profile}
        onSuccess={() => setIsSuccess(true)}
        onError={() => {}}
      />
    </Box>
  );
};

export default ApplyJobPage;
