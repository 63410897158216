import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageWithPlaceholder from "../ImageWithPlaceholder/ImageWithPlaceholder";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import ThemedButton from "../Custom/Button";
import { Opening } from "../../api/hooks/openings/openings.types";

const useStyles = makeStyles({
  card: {
    borderRadius: "16px",
    backgroundColor: "#121212",
  },
  banner: {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    width: "100%",
  },
  description: {
    padding: "12px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  markdown: {
    maxWidth: "100%",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
});

const positionTypeLabel = {
  partTime: "Part Time",
  fullTime: "Full Time",
  contract: "Contract",
  freelance: "Freelance",
  internship: "Internship",
};

const jobLocationLabel = {
  onSite: "On site",
  remote: "Remote",
  onSiteOrRemote: "On site or Remote",
};

function JobCard({ opening }: { opening: Opening }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const onShare = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      const openingTgUrl = `https://t.me/${process.env.REACT_APP_BOT_NAME}?start=OPN_${opening.id}`;

      const telegramShareUrl = `https://t.me/share/url?text=${encodeURIComponent(
        "\nHey, dYdX is hiring a Some Opening, you could be interested!\nCheck it out on this job bot."
      )}&url=${encodeURIComponent(openingTgUrl)}`;

      window.open(telegramShareUrl, "_blank");
    },
    [opening]
  );

  const onApply = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      navigate(`/openings/${opening.id}`);
    },
    [navigate, opening]
  );

  function formatDate(inputDate: string): string {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Use the toLocaleDateString method to format the date
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  }
  return (
    <Box
      className={classes.card}
      onClick={() => navigate(`/openings/${opening.id}`)}
    >
      <ImageWithPlaceholder
        src={opening.company.bannerPath}
        alt={opening.company.name}
        className={classes.banner}
        imageWidth={250}
        imageHeight={100}
      />
      <Box className={classes.description}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", textAlign: "left" }}
          >
            💎 {opening.company.name} is hiring! 💎
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            {formatDate(opening.createdAt)}
          </Typography>
        </Box>
        <Markdown className={classes.markdown}>
          {opening.company.summary}
        </Markdown>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Position: {opening.title}
        </Typography>
        <Typography>
          🕒 {positionTypeLabel[opening.positionType]} - 📍{" "}
          {jobLocationLabel[opening.jobLocation]}
        </Typography>
        <Markdown className={classes.markdown}>{opening.summary}</Markdown>
        {opening.status !== 1 && (
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            🔒 This position has been CLOSED 🔒
          </Typography>
        )}
        <Box
          className={classes.buttonContainer}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <ThemedButton
            onClick={onShare}
            sx={{
              width: "25%",
              padding: "6px !important",
            }}
          >
            Share
          </ThemedButton>
          <ThemedButton
            onClick={onApply}
            sx={{
              width: "25%",
              padding: "6px !important",
            }}
          >
            Apply
          </ThemedButton>
        </Box>
      </Box>
    </Box>
  );
}

export default JobCard;
