import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import type { BoxProps } from "@mui/material";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#3B0742",
    border: "2px solid #A05CA9",
    borderRadius: "16px",
    padding: "16px",
  },
});

const UnicastBox: React.FC<BoxProps> = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.container} ${className}`} {...props}>
      {children}
    </Box>
  );
};

export default UnicastBox;
