import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import ImageWithPlaceholder from "../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { useFetchTipOfTheDay } from "../api/hooks/tips/useGetTip";
import { useTelegram } from "../utils/Telegram.Provider";
import React from "react";

const useStyles = makeStyles({
  typography: {
    textAlign: "center",
    fontFamily: "Helvetica Neue",
  },
  welcomeBanner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    //backgroundColor: "#3B0742",
    background: "linear-gradient(to right, #081544, #4F0069)",
    //background: "linear-gradient(to right, #3B0742, #4F0069)",
    borderRadius: "16px",
    padding: "16px",
  },
  divider: {
    backgroundColor: "white",
    height: "2px",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    marginLeft: "12% !important",
    marginRight: "12% !important",
  },
  tipContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
  },
  tipBanner: {
    borderRadius: "14px",
  },
  homeContainer: {
    padding: "16px",
  },
  tipContent: {
    borderRadius: "16px",
    //backgroundColor: "#3B0742",
    background: "linear-gradient(to top right, #081544, #4F0069)",
    padding: "16px",
  },
});

const HomePage = () => {
  const classes = useStyles();
  const webApp = useTelegram();

  React.useEffect(() => {
    webApp?.BackButton.hide();
  }, [webApp]);

  const { data: tip, isLoading } = useFetchTipOfTheDay();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!tip) {
    return <></>;
  }

  return (
    <Box className={classes.homeContainer}>
      <Box className={classes.welcomeBanner}>
        <Typography
          variant="h3"
          className={classes.typography}
          sx={{ fontWeight: "bold", fontFamily: "Helvetica Neue" }}
        >
          Unicast.ai
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          Find great opportunities in the Web3 space! ✨
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.tipContainer}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", fontFamily: "Helvetica Neue" }}
        >
          {tip?.title}
        </Typography>
        <ImageWithPlaceholder
          src={tip.imageUrl}
          alt={`${tip!.title} banner`}
          imageWidth={1280}
          imageHeight={566}
          className={classes.tipBanner}
        />
        <Box className={classes.tipContent}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              fontSize: "18px",
              fontFamily: "Helvetica Neue",
            }}
          >
            {tip?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
