import React from "react";

interface HomeIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const HomeIcon: React.FC<HomeIconProps> = ({
  color = "#7A7A7A",
  width = 32,
  height = 32,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1665 14.7718C1.1665 11.7198 1.1665 10.1945 1.85984 8.9305C2.5505 7.66517 3.81584 6.88117 6.34517 5.3105L9.01184 3.65584C11.6852 1.99584 13.0225 1.1665 14.4998 1.1665C15.9772 1.1665 17.3132 1.99584 19.9878 3.65584L22.6545 5.3105C25.1838 6.88117 26.4492 7.66517 27.1412 8.9305C27.8332 10.1958 27.8332 11.7198 27.8332 14.7705V16.7998C27.8332 21.9998 27.8332 24.6012 26.2705 26.2172C24.7078 27.8332 22.1945 27.8332 17.1665 27.8332H11.8332C6.80517 27.8332 4.2905 27.8332 2.72917 26.2172C1.16784 24.6012 1.1665 22.0012 1.1665 16.7998V14.7718Z"
        stroke={color} // Use the color prop here
        strokeWidth="1.65"
      />
      <path
        d="M14.5 18.5V22.5"
        stroke={color} // Use the color prop here
        strokeWidth="1.65"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomeIcon;
