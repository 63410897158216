import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

interface CustomBoxProps extends BoxProps {
  borderRadius?: string;
  borderWidth?: string;
  borderColor?: string;
}

const CustomBox: React.FC<CustomBoxProps> = ({
  children,
  borderRadius = "14px",
  borderWidth = "2px",
  borderColor = "#6A27A7",
  sx,
  ...otherProps
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      sx={{
        borderRadius: borderRadius,
        border: `${borderWidth} solid ${borderColor}`,
        backgroundColor: isDarkMode ? "#121212" : "#ffffff",
        boxShadow: 1, // Optional: Add shadow if needed
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default CustomBox;
