// ProfileIcon.tsx
import React from "react";

const ProfileIcon: React.FC<{
  color?: string;
  width?: number;
  height?: number;
}> = ({ color = "#7A7A7A", width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 14.5C17.5376 14.5 20 12.0376 20 9C20 5.96243 17.5376 3.5 14.5 3.5C11.4624 3.5 9 5.96243 9 9C9 12.0376 11.4624 14.5 14.5 14.5Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M5 25.5C5 21.105 9.70129 18 14.5 18C19.2987 18 24 21.105 24 25.5"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ProfileIcon;
