import { Avatar, Box, Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { useTelegram } from "../utils/Telegram.Provider";
import CustomBox from "../components/Box/Box";
import React from "react";
import { useNavigate } from "react-router-dom";

const HelpPage = () => {
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const closeMiniApp = React.useCallback(() => {
    webApp?.close();
  }, [webApp]);

  return (
    <Box
      sx={{
        padding: "16px 8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CustomBox
        sx={{
          height: "100%",
          backgroundColor: "#150028",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4px",
              paddingLeft: "2%",
              paddingRight: "2%",
              margin: 2,
            }}
          >
            <Avatar
              variant="square"
              src={"/assets/images/logo.png"}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              padding: "8px 2% 8px 2%",
              margin: 2,
              flex: 1, // Allow this box to grow and fill space
              textAlign: "center", // Center text
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: 2, fontSize: "17px" }}
            >
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Unicast.ai
              </Box>{" "}
              is a company based in Europe 🇪🇺
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: 2, fontSize: "17px" }}
            >
              We've built this recruiting bot to help people find great
              opportunities in the Web3 space!
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: 2, fontSize: "17px" }}
            >
              You can browse & apply to jobs{" "}
              <Box component="span" sx={{ fontWeight: "bold" }}>
                directly on Telegram!
              </Box>{" "}
              Share jobs & refer friends to{" "}
              <Box component="span" sx={{ fontWeight: "bold" }}>
                earn rewards!
              </Box>{" "}
              (coming soon)
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: 2, fontSize: "17px" }}
            >
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Join
              </Box>{" "}
              our community channel and follow us on X 🤝
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "17px" }}>
              <Link href="https://x.com/unicastai" underline="hover">
                x.com/unicast
              </Link>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: 2, fontSize: "17px" }}
            >
              <Link href="https://t.me/unicastaicommunity" underline="hover">
                t.me/unicastaicommunity
              </Link>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "17px" }}>
              To reach out to us: team@unicast.ai
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "16px",
          }}
        >
          <Button
            onClick={closeMiniApp}
            sx={{
              width: "90%",
              borderRadius: "24px",
              backgroundColor: "#61157D",
              color: "#FFFFFF",
              marginBottom: 2,
              "&:hover": {
                backgroundColor: "#61157D",
              },
              "&:active": {
                backgroundColor: "#61157D",
              },
            }}
          >
            Close
          </Button>
        </Box>
      </CustomBox>
    </Box>
  );
};

export default HelpPage;
