import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface Tip {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
}

const fetchTipOfTheDay = async (): Promise<Tip> =>
  fetcher({
    resource: `api/tips/tip-of-today`,
    method: "GET",
  });

export const useFetchTipOfTheDay = () => {
  return useQuery<Tip, Error>("tipOfTheDay", fetchTipOfTheDay, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  });
};
