import React from "react";
import ProfileEditionForm from "./components/ProfileEditionForm";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useGetProfile } from "../../api/hooks/profiles/useGetProfile";
import { useTelegram } from "../../utils/Telegram.Provider";
import { useNavigate } from "react-router-dom";

function ProfileEditionPage() {
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const { data, isLoading } = useGetProfile(webApp?.initDataUnsafe?.user?.id);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ProfileEditionForm profile={data?.profile} onCloseEdition={onBackClick} />
  );
}

export default ProfileEditionPage;
