interface FetcherParams {
  resource: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  data?: Record<string, any> | FormData;
  params?: Record<string, any>;
}

const fetcher = async ({
  resource,
  method = "GET",
  data = undefined,
  params = undefined,
}: FetcherParams) => {
  const headers: HeadersInit = {};

  let body: BodyInit | null = null;

  if (data) {
    if (data instanceof FormData) {
      body = data;
    } else {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }
  }

  const query = params
    ? `?${new URLSearchParams(params as Record<string, string>).toString()}`
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/${resource}${query}`,
    {
      method,
      headers: {
        ...headers,
      },
      body: method === "GET" ? null : body,
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "An error occurred");
  }

  return response.json();
};

export default fetcher;
