import React from "react";
import { Typography, Alert, Box } from "@mui/material";
import Markdown from "react-markdown";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../components/NotFound/NotFound";
import { useNavigate, useParams } from "react-router-dom";
import ImageWithPlaceholder from "../components/ImageWithPlaceholder/ImageWithPlaceholder";
import UnicastBox from "../components/Custom/UnicastBox";
import ThemedButton from "../components/Custom/Button";
import { useFetchOpening } from "../api/hooks/openings/useFetchOpeningById";
import { useTelegram } from "../utils/Telegram.Provider";

const useStyles = makeStyles({
  container: {
    paddingTop: "16px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
  },
  banner: {
    borderRadius: "16px",
  },
  company: {
    padding: "8px",
  },
  opening: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "4px",
  },
  companyTitle: {
    textAlign: "center",
    paddingBottom: "24px",
  },
  openingTitle: {
    textAlign: "center",
    paddingTop: "8px",
  },
  content: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "16px",
    width: "100%",
  },
  markdown: {
    maxWidth: "100%",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
});

const JobDescriptionPage = () => {
  const classes = useStyles();
  const { openingId } = useParams<{ openingId: string }>();

  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/openings");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const { data: jobOpening, isLoading, isError } = useFetchOpening(openingId);

  const applyForOpening = React.useCallback(async () => {
    if (!jobOpening || !webApp) {
      return;
    }

    if (jobOpening.applicationLink) {
      const payload = {
        openingId: jobOpening?.id,
        telegramId: String(webApp.initDataUnsafe?.user?.id || "N/A"),
        telegramUsername: String(
          webApp.initDataUnsafe?.user?.username || "N/A"
        ),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/external-applications`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to create external application: ${response.statusText}`
          );
        }
        webApp.openLink(jobOpening.applicationLink);
      } catch (error) {
        console.error(
          "Error while tracking external application the backend:",
          error
        );
      }
    } else {
      navigate(`/openings/${jobOpening?.id}/apply`);
    }
  }, [jobOpening, navigate, webApp]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!jobOpening && !isLoading) {
    return <NotFound />;
  }

  if (isError) {
    return <Alert severity="error">{"Something wen wrong"}</Alert>;
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.company}>
          <Box>
            <Typography
              className={classes.companyTitle}
              sx={{ fontWeight: "bold" }}
              variant="h4"
            >
              {jobOpening?.company?.name}
            </Typography>
          </Box>
          <ImageWithPlaceholder
            imageWidth={1280}
            imageHeight={466}
            src={jobOpening!.company.bannerPath}
            alt={jobOpening!.company.name}
            className={classes.banner}
          />
          <Markdown className={classes.markdown}>
            {jobOpening!.company.summary}
          </Markdown>
        </Box>
        <UnicastBox className={classes.opening}>
          <Typography
            className={classes.openingTitle}
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {jobOpening!.title}
          </Typography>
          <div className={classes.content}>
            <Markdown className={classes.markdown}>
              {jobOpening!.description}
            </Markdown>
          </div>
        </UnicastBox>
        <Box className={classes.actions}>
          <ThemedButton onClick={applyForOpening} sx={{ width: "90%" }}>
            Apply now
          </ThemedButton>
        </Box>
      </Box>
    </>
  );
};

export default JobDescriptionPage;
