import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface JobOpening {
  id: string;
  companyId: string;
  title: string;
  description: string;
  company: { id: string; name: string; bannerPath: string; summary: string };
  applicationLink?: string;
}

const fetchOpening = async (jobId?: string) =>
  fetcher({ resource: `api/openings/${jobId}`, method: "GET" });

export const useFetchOpening = (jobId?: string) => {
  return useQuery<JobOpening>(
    ["jobOpening", jobId],
    () => fetchOpening(jobId),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      enabled: !!jobId,
    }
  );
};
