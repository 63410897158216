import { useMutation, useQueryClient } from "react-query";
import fetcher from "../../base-fetcher";

interface DeleteProfileParams {
  telegramId: number;
}

export const useDeleteProfile = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, DeleteProfileParams>(
    ({ telegramId }) => {
      const endpoint = `api/profiles/telegram/${telegramId}`;

      return fetcher({
        resource: endpoint,
        method: "DELETE",
      });
    },
    {
      onSuccess: (_, { telegramId }) => {
        if (telegramId) {
          queryClient.invalidateQueries(["profile", telegramId]);
        }
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) {
          onError();
        }
      },
    }
  );

  return mutation;
};
