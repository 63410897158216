import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const CustomButton = styled(Button)(() => ({
  backgroundColor: "#6B01D4",
  borderRadius: "24px",
  color: "#FFFFFF !important",
  fontSize: "16px",
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#6B01D4",
  },
  "&:active": {
    backgroundColor: "#6B01D4",
  },
  "&.Mui-disabled": {
    backgroundColor: "#A687B0",
    color: "#E5D5ED",
  },
}));

const ThemedButton = (props: ButtonProps) => {
  return <CustomButton {...props} />;
};

export default ThemedButton;
