import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const CustomSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFFFFF", // White for the thumb when checked
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#AD32BE", // Purple for the track when checked
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#555555", // Dark Gray for the track when unchecked
  },
}));

const ThemedSwitch = (props: SwitchProps) => {
  return <CustomSwitch {...props} />;
};

export default ThemedSwitch;
