import React from "react";
import { useInfiniteQuery } from "react-query";
import { OpeningRoleValue } from "../../../utils/opening.filters";
import { Filters, Opening } from "./openings.types";
import fetcher from "../../base-fetcher";

const DEFAULT_PAGE_SIZE = 20;

const fetchOpenings = async (filters: Filters, pageNumber = 0) => {
  let url = `api/openings`;

  const queryParams = new URLSearchParams();
  queryParams.append("page", String(pageNumber));
  queryParams.append("size", String(DEFAULT_PAGE_SIZE));

  if (filters.query) {
    queryParams.append("q", filters.query);
  }

  filters.roles.forEach((role: OpeningRoleValue) => {
    queryParams.append("roles[]", role);
  });

  if (queryParams.toString()) {
    url += `?${queryParams.toString()}`;
  }

  return fetcher({ resource: url, method: "GET" });
};

const useFetchOpenings = (filters: Filters) => {
  const queryKey = React.useMemo(() => {
    return ["openings", JSON.stringify(filters)];
  }, [filters]);

  return useInfiniteQuery<
    { count: number; items: Opening[]; nextPage: number | null },
    Error
  >(queryKey, ({ pageParam = 0 }) => fetchOpenings(filters, pageParam), {
    getNextPageParam: (lastPage) => lastPage.nextPage ?? null,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export default useFetchOpenings;
