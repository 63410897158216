import { useMutation, useQueryClient } from "react-query";
import { Profile } from "../../../api/hooks/profiles/useGetProfile";
import fetcher from "../../base-fetcher";

interface SubmitProfileParams {
  formData: FormData;
  isUpdate: boolean;
  telegramId: number;
}

export const useUpsertProfile = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Profile, Error, SubmitProfileParams>(
    ({ formData, isUpdate, telegramId }) => {
      const method = isUpdate ? "PUT" : "POST";
      const endpoint = isUpdate
        ? `api/profiles/telegram/${telegramId}`
        : "api/profiles";

      return fetcher({
        resource: endpoint,
        method,
        data: formData,
      });
    },
    {
      onSuccess: (_, { telegramId }) => {
        if (telegramId) {
          queryClient.invalidateQueries(["profile", telegramId]);
        }
      },
    }
  );

  return mutation;
};
