import React from "react";
import { useTelegram } from "../utils/Telegram.Provider";
import AnimatedSuccess from "../components/AnimatedSuccess";
import { Box, Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import CustomBox from "../components/Box/Box";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    paddingBottom: "32px",
    background: "linear-gradient(to right, #C43A99, #5E0BA4)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  svg: {
    paddingBottom: "15%",
  },
});

function ApplicationSuccess() {
  const classes = useStyles();
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const closeMiniApp = React.useCallback(() => {
    navigate("/openings");
  }, [navigate]);

  return (
    <Box>
      <CustomBox
        sx={{
          height: "80%",
          marginX: "8px",
          backgroundColor: "#150028",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "15%",
          marginTop: "10%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
            margin: 2,
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              className={classes.header}
              variant="h5"
              sx={{
                marginBottom: 2,
                fontWeight: "bold",
              }}
            >
              Your application was submitted successfully!
            </Typography>
            <Box className={classes.svg}>
              <AnimatedSuccess />
            </Box>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "17px",
              }}
            >
              The company will reach out directly to you if they proceed with
              your application.
            </Typography>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "17px",
              }}
            >
              In the meantime, do follow our X and join our Web3 Jobboard! 🤝
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                href="http://x.com/unicastai"
                underline="hover"
                sx={{
                  marginBottom: 1,
                  fontSize: "17px",
                }}
              >
                x.com/unicast
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        ></Box>
      </CustomBox>
      <Box
        sx={{
          marginX: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2%",
        }}
      >
        <Button
          onClick={closeMiniApp}
          sx={{
            width: "100%",
            borderRadius: "24px",
            backgroundColor: "#6B01D4",
            color: "#FFFFFF",
            fontSize: "15px", // Font size set to 20px
            fontWeight: "bold", // Makes the font bold
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}

export default ApplicationSuccess;
