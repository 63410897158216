import { Skeleton } from "@mui/material";
import React from "react";

interface ImageWithPlaceholderProps {
  src: string;
  alt: string;
  imageWidth: number;
  imageHeight: number;
  width?: string;
  height?: string;
  className?: string;
}

const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = ({
  src,
  alt,
  imageWidth,
  imageHeight,
  width = "100%",
  className,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [calculatedHeight, setCalculatedHeight] = React.useState<number>(160);

  React.useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;

      const finalWidth =
        width === "100%"
          ? containerWidth
          : typeof width === "number"
          ? width
          : parseFloat(width);

      const height = (imageHeight / imageWidth) * finalWidth;
      setCalculatedHeight(height);
    }
  }, [width, imageWidth, imageHeight]);

  return (
    <div ref={containerRef}>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width={width}
          height={calculatedHeight}
          animation="wave"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.1)",
          }}
          className={className}
        />
      )}
      <img
        src={src}
        alt={alt}
        style={{
          width: "100%",
          height: "auto",
          display: isLoading ? "none" : "block",
        }}
        onLoad={() => setIsLoading(false)}
        className={className}
      />
    </div>
  );
};

export default ImageWithPlaceholder;
