import React from "react";
import { Typography, Divider } from "@mui/material";
import Markdown from "react-markdown";
import { makeStyles } from "@mui/styles";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import NotFound from "../components/NotFound/NotFound";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCompany } from "../api/hooks/companies/useGetCompany";
import { useTelegram } from "../utils/Telegram.Provider";

const useStyles = makeStyles({
  container: {
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "8px",
  },
  title: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  image: {
    width: "70vw",
    height: "auto",
  },
  imageContainer: {
    textAlign: "center",
  },
  description: {
    paddingBottom: "8px",
    paddingTop: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  markdown: {
    maxWidth: "100%",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
});

const CompanyDescriptionPage = () => {
  const classes = useStyles();

  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const { companyId } = useParams<{ companyId: string }>();

  const { data: company, isLoading } = useFetchCompany(companyId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!company && !isLoading) {
    return <NotFound />;
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title} variant="h3">
          {company!.name}
        </Typography>
        <div className={classes.imageContainer}>
          <img
            src={company!.bannerPath}
            alt={`${company!.name} banner`}
            className={classes.image}
          />
        </div>
      </div>

      <Divider />
      <div className={classes.description}>
        <Markdown className={classes.markdown}>{company!.description}</Markdown>
      </div>

      <Divider />
    </div>
  );
};

export default CompanyDescriptionPage;
