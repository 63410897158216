import React from "react";

export const useFileInput = () => {
  const [file, setFile] = React.useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setFile(event.target.files[0]);
    }
  };

  const clearFile = React.useCallback(() => {
    setFile(null);
    (document.getElementById("file-input") as HTMLInputElement).value = "";
  }, []);

  return { file, handleFileChange, clearFile };
};
