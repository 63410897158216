import React from "react";
import { useApplicationForm } from "../hooks/form.hook";
import { useTelegram } from "../../../utils/Telegram.Provider";
import { useFileInput } from "../hooks/fileInput.hook";
import { TextField, Typography, FormControlLabel, Box } from "@mui/material";
import ThemedSwitch from "../../../components/Custom/Switch";
import { makeStyles } from "@mui/styles";
import { FileUploadField } from "../../../components/FileUploadField/FileUploadField";
import UnicastBox from "../../../components/Custom/UnicastBox";
import ThemedButton from "../../../components/Custom/Button";
import { JobOpening } from "../../../api/hooks/openings/useFetchOpeningById";
import { Profile } from "../../../api/hooks/profiles/useGetProfile";

interface ApplicationFormState {
  firstName: string;
  lastName: string;
  email: string;
  xProfile: string;
  portfolio: string;
  comment: string;
}

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "32px",
    width: "100%",
  },
  header: {
    padding: "16px",
    paddingBottom: "32px",
  },
  gradientText: {
    background: "linear-gradient(to right, #C43A99, #5E0BA4)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});

const FormFields = ({
  formData,
  handleChange,
  file,
  handleFileChange,
  clearFile,
  hideResumeField,
}: {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
  hideResumeField: boolean;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      <TextField
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.firstName.length > 25}
        size="small"
        fullWidth
        required
      />
      <TextField
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.lastName.length > 25}
        size="small"
        fullWidth
        required
      />
    </div>
    <TextField
      name="email"
      label="Email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.email.length > 50}
      size="small"
      fullWidth
      required
    />
    {!hideResumeField && (
      <FileUploadField
        file={file}
        handleFileChange={handleFileChange}
        clearFile={clearFile}
        isResumeRequired
      />
    )}
    <TextField
      name="xProfile"
      label="X Profile"
      type="url"
      value={formData.xProfile}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.xProfile.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="portfolio"
      label="Github, Linkedin or Portfolio"
      type="url"
      value={formData.portfolio}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.portfolio.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="comment"
      label="Comments"
      multiline
      rows={4}
      value={formData.comment}
      onChange={handleChange}
      inputProps={{ maxLength: 1000 }}
      error={formData.comment.length > 1000}
      fullWidth
    />
  </div>
);

export const ApplicationForm = ({
  profile,
  jobOpening,
  onSuccess,
  onError,
}: {
  jobOpening: JobOpening;
  profile?: Profile;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const telegram = useTelegram();

  const { formData, handleChange } = useApplicationForm<ApplicationFormState>({
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    email: profile?.email || "",
    xProfile: profile?.xProfile || "",
    portfolio: profile?.portfolio || "",
    comment: "",
  });

  const { file, handleFileChange, clearFile } = useFileInput();
  const [shareTelegramContact, setShareTelegramContact] =
    React.useState<boolean>(true);
  const [createProfileEnabled, setCreateProfileEnabled] =
    React.useState<boolean>(true);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [useProfileResume, setUseProfileResume] = React.useState<boolean>(
    !!profile
  );

  const createProfile = React.useCallback((payload: FormData) => {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/profiles`, {
      method: "POST",
      body: payload,
    });
  }, []);

  const createApplication = React.useCallback((payload: FormData) => {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/applications`, {
      method: "POST",
      body: payload,
    });
  }, []);

  const { applicationPayload, profilePayload } = React.useMemo(() => {
    const applicationPayload = new FormData();
    const profilePayload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        applicationPayload.append(key, value);
        if (key !== "comment") {
          profilePayload.append(key, value);
        }
      }
    });

    applicationPayload.append("openingId", jobOpening.id);
    applicationPayload.append("companyId", jobOpening.companyId);
    if (file) {
      applicationPayload.append("resume", file);
      profilePayload.append("resume", file);
    }

    if (profile && useProfileResume) {
      applicationPayload.append("resumePath", profile.resumePath);
    }

    if (telegram) {
      const id = telegram.initDataUnsafe?.user?.id!;
      const username = telegram.initDataUnsafe?.user?.username!;
      profilePayload.append("telegramId", String(id));
      if (shareTelegramContact) {
        applicationPayload.append("telegramId", String(id));
        applicationPayload.append("telegramUsername", username);
      }
    }

    return { applicationPayload, profilePayload };
  }, [
    file,
    profile,
    formData,
    telegram,
    jobOpening,
    useProfileResume,
    shareTelegramContact,
  ]);

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true);
    const requests = [createApplication(applicationPayload)];
    if (!profile && createProfileEnabled) {
      requests.push(createProfile(profilePayload));
    }
    function handleSuccess() {
      onSuccess();
      setIsLoading(false);
    }

    function handleError() {
      onError();
      setIsLoading(false);
    }

    Promise.all(requests)
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError();
      });
  }, [
    applicationPayload,
    createApplication,
    createProfile,
    createProfileEnabled,
    onError,
    onSuccess,
    profile,
    profilePayload,
  ]);

  const isFormValid = React.useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const areTextFieldsValid =
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      emailRegex.test(formData.email);

    if (useProfileResume) {
      return areTextFieldsValid;
    }
    return !!file && file.type === "application/pdf" && areTextFieldsValid;
  }, [
    file,
    formData.email,
    formData.firstName,
    formData.lastName,
    useProfileResume,
  ]);

  const classes = useStyles();

  return (
    <Box style={{ padding: "8px 2%" }}>
      <UnicastBox>
        <Box className={classes.header}>
          <Box className={classes.gradientText}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {jobOpening.title}
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              At {jobOpening.company?.name}
            </Typography>
          </Box>
        </Box>
        <Box>
          <FormFields
            formData={formData}
            handleChange={handleChange}
            file={file}
            handleFileChange={handleFileChange}
            clearFile={clearFile}
            hideResumeField={useProfileResume}
          />
          {!!profile && (
            <FormControlLabel
              control={
                <ThemedSwitch
                  checked={useProfileResume}
                  onChange={(e) => setUseProfileResume(e.target.checked)}
                />
              }
              label="Use resume from my profile"
            />
          )}
          <FormControlLabel
            control={
              <ThemedSwitch
                checked={shareTelegramContact}
                onChange={(e) => setShareTelegramContact(e.target.checked)}
              />
            }
            label="Share my Telegram ID with company"
          />
          {!profile && (
            <FormControlLabel
              control={
                <ThemedSwitch
                  checked={createProfileEnabled}
                  onChange={(e) => setCreateProfileEnabled(e.target.checked)}
                />
              }
              label="Save info for future applications"
            />
          )}
        </Box>
      </UnicastBox>
      <Box className={classes.actions}>
        <ThemedButton
          disabled={!isFormValid || isLoading}
          onClick={handleSubmit}
          sx={{ width: "90%" }}
        >
          Apply now
        </ThemedButton>
      </Box>
    </Box>
  );
};
