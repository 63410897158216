import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface Company {
  id: string;
  name: string;
  bannerPath: string;
  description: string;
  summary: string;
}

const fetchCompany = async (companyId?: string): Promise<Company> =>
  fetcher({ resource: `api/companies/${companyId}`, method: "GET" });

export const useFetchCompany = (companyId?: string) => {
  return useQuery<Company>(
    ["company", companyId],
    () => fetchCompany(companyId),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!companyId,
    }
  );
};
