import React, { useEffect, useState } from "react";

interface ProfilePhotoProps {
  telegramId: number;
  userName: string;
}

const styles = {
  profilePicture: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
  } as React.CSSProperties,
  initialsFallback: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "4em",
    backgroundColor: "#3B0742",
    color: "white",
  } as React.CSSProperties,
};

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
  telegramId,
  userName,
}) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [initials, setInitials] = useState<string>("");

  useEffect(() => {
    if (!!imageSrc || initials.length > 0) {
      return;
    }
    const generateInitials = (name: string) => {
      return name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    };

    const fetchProfilePhoto = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/profiles/telegram/${telegramId}/profile-picture`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
        } else {
          setInitials(generateInitials(userName));
        }
      } catch (error) {
        setInitials(generateInitials(userName));
      }
    };

    fetchProfilePhoto();

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [telegramId, userName, imageSrc, initials]);

  return (
    <>
      {imageSrc ? (
        <img src={imageSrc} alt="Profile" style={styles.profilePicture} />
      ) : (
        <div style={styles.initialsFallback}>{initials}</div>
      )}
    </>
  );
};

export default ProfilePhoto;
