import React from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import JobCard from "../../components/JobCard/JobCard";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
// import DebouncedInput from "../../components/DebouncedInput/DebouncedInput";
import FilterDrawer, { INITIAL_FILTERS } from "./components/FiltersDrawer";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import useFetchOpenings from "../../api/hooks/openings/useGetAllOpenings";
import { Filters, Opening } from "../../api/hooks/openings/openings.types";
import { useTelegram } from "../../utils/Telegram.Provider";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  container: {
    paddingTop: "2px",
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textAlign: "center",
    marginTop: "48px",
    background: "linear-gradient(to right, #000000, #4F0069)",
    borderRadius: "16px",
    padding: "16px 32px",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      padding: "8px",
    },
  },
  filtersContainer: {
    position: "sticky",
    top: "0",
    zIndex: 1000,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    backgroundColor: "#660872",
  },
  filters: {
    backgroundColor: "#121212",
    border: "0.5px solid #7A7A7A",
    borderRadius: "24px",
    height: "36px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  searchInput: {
    width: "100%",
    height: "100%",
    backgroundColor: "inherit",
    color: "inherit",
    fontSize: "16px",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  boardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
  },
}));

function AllOpeningsPage() {
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filters, setFilters] = React.useState<Filters>(INITIAL_FILTERS);
  const [open, setOpen] = React.useState<boolean>(false);

  // const setQueryString = React.useCallback((value: string) => {
  //   setFilters((previous) => ({ ...previous, query: value }));
  // }, []);

  const observerElem = React.useRef(null);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useFetchOpenings(filters);

  const handleObserver = React.useCallback(
    (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  React.useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (observerElem.current) observer.observe(observerElem.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.banner}>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            sx={{
              fontWeight: "bold",
              fontSize: "30px",
              fontFamily: "Helvetica Neue",
            }}
          >
            Ready to find your dream Web3 job?
          </Typography>
        </Box>
        <Box className={classes.filtersContainer}>
          <Box className={classes.filters} onClick={() => setOpen(true)}>
            <SearchIcon />
            {/* <DebouncedInput
              className={classes.searchInput}
              placeholder={"Search ..."}
              value={filters.query}
              onChange={setQueryString}
            /> */}
            <Typography sx={{ width: "100%", color: "#7A7A7A !important" }}>
              Search by category
            </Typography>
            <IconButton color="inherit">
              <TuneIcon />
            </IconButton>
          </Box>
        </Box>
        <BoardContent
          className={classes.boardContent}
          isLoading={isLoading}
          openings={data?.pages.flatMap((page) => page.items) || []}
        />
        <div ref={observerElem} />
        {isFetchingNextPage && (
          <Box className={classes.spinnerContainer}>
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Box>
      <FilterDrawer
        open={open}
        onClose={() => setOpen(false)}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
}

const BoardContent = ({
  isLoading,
  openings,
  className,
}: {
  isLoading: boolean;
  openings: Opening[];
  className?: string;
}) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box className={className}>
      {openings.map((opening) => (
        <JobCard key={opening.id} opening={opening} />
      ))}
    </Box>
  );
};

export default AllOpeningsPage;
