import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  xProfile?: string;
  portfolio?: string;
  aboutMe?: string;
  resumePath: string;
}

export const useGetProfile = (telegramId?: number) => {
  return useQuery<{ profile: Profile | undefined }, Error>(
    ["profile", telegramId],
    () =>
      fetcher({
        resource: `api/profiles/telegram/${telegramId}`,
        method: "GET",
      }),
    {
      enabled: !!telegramId,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
