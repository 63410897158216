import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

interface Option {
  label: string;
  value: string;
}

interface CollapsibleCheckboxSectionProps {
  title: string;
  options: Option[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
}

const useStyles = makeStyles({
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

const CollapsibleFilter = ({
  title,
  options,
  selectedOptions,
  onChange,
}: CollapsibleCheckboxSectionProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;

    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    onChange(newSelectedOptions);
  };

  const classes = useStyles();

  return (
    <Accordion
      expanded
      sx={{
        bgcolor: "#660872",
        color: "#FFFFFF",
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.optionsContainer}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <CustomCheckbox
                checked={selectedOptions.includes(option.value)}
                onChange={handleCheckboxChange}
                name={option.value}
              />
            }
            label={option.label}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const CustomCheckbox = ({
  checked,
  onChange,
  name,
}: {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      name={name}
      sx={{
        color: "#FFFFFF",
        "&.Mui-checked": {
          color: "#3B0742",
        },
        "& .MuiSvgIcon-root": {
          fill: "#FFFFFF",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiCheckbox-root": {
          borderColor: "#FFFFFF",
        },
        "& .MuiCheckbox-checked": {
          borderColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
        },
      }}
    />
  );
};

export default CollapsibleFilter;
